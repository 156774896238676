<template>
  <div class="flex items-center">
    <BaseIcon
      class="text-xs"
      :class="[fillColor]"
      :icon="starIcon(1)"
    />
    <BaseIcon
      class="text-xs"
      :class="[fillColor]"
      :icon="starIcon(2)"
    />
    <BaseIcon
      class="text-xs"
      :class="[fillColor]"
      :icon="starIcon(3)"
    />
    <BaseIcon
      class="text-xs"
      :class="[fillColor]"
      :icon="starIcon(4)"
    />
    <BaseIcon
      class="text-xs"
      :class="[fillColor]"
      :icon="starIcon(5)"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    value: number
    fillColor?: string
  }>(),
  {
    fillColor: 'text-gray-900',
  },
)

const starIcon = (star: number): string => {
  const starRating = props.value - star + 1
  if (starRating <= 0) {
    return 'fal fa-star'
  } else if (starRating >= 1) {
    return 'fas fa-star'
  }
  return 'fas fa-star-half-stroke'
}
</script>
